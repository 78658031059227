import React from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import './Map.css'

const MapContainer = (props) => {
  const mapStyles = {
    width: '100%',
    height: '100%',
  };

  const getQueryParameters = () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    return {
      lat: params.get('lat') || 0, 
      long: params.get('long') || 0, 
    };
  };

  const { lat, long } = getQueryParameters();

  return (
    <div className="max-container">
      <Map
        google={props.google}
        zoom={18}
        style={mapStyles}
        initialCenter={{
          lat: parseFloat(lat),
          lng: parseFloat(long),
        }}
        disableDefaultUI={true}
      >
       <Marker
  name={'Your Marker'}
  icon={{
    url: "https://cdn.venuemonk.com/map-icon-image.png",
    scaledSize: new window.google.maps.Size(40, 80)
  }}
  position={{
    lat: parseFloat(lat),
    lng: parseFloat(long),
  }}
  options={{
    zoomControl: false, 
    panControl: false,  
  }}
/>
      </Map>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAP_API,
})(MapContainer);
